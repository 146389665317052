<template>
    <!--物流中心-->
    <div class="order-show grey-bg">
        <!--头部开始-->
        <div class="show-header vertical-center space-between">
          <div class="header-tit" v-if="orderShow.status == 0">待发货</div>
          <div class="header-tit" v-else-if="orderShow.status == 1">已完成</div>
          <div class="header-tit" v-else-if="orderShow.status == -1">已关闭</div>
          <div class="header-tit" v-else-if="orderShow.status == 2">待收货</div>

          <img src="~assets/images/user/order-suc.png" v-if="orderShow.status == 1 || orderShow.status == -1" class="header-img" />
          <img src="~assets/images/user/order-await.png" v-else class="header-img"/>
        </div>
        <!--头部结束-->

        <!--地址显示开始-->
        <div class="address-box vertical-center">
            <img src="~assets/images/public/location-icon.png" class="location-icon"/>
            <div class="location-text-box">
                <div class="location-user vertical-center">
                    <span class="name white-space">{{ orderShow.contact_name }}</span>
                    <span class="tell">{{ orderShow.contact_mobile }}</span>
                </div>
                <div class="location-text white-space-2">{{ orderShow.address }}</div>
            </div>
        </div>
        <!--地址显示结束-->

        <!--物流详情开始-->
        <div class="logistics-item" v-if="orderShow.status != 0 && orderShow.status != -1">
          <!-- 物流信息 -->
          <div class="logistics-info vertical-center">
            <p>{{orderShow.exp_name}} {{orderShow.exp_number}}</p>
            <img src="~assets/images/user/logistics-copy-icon.png" class="copy-btn" :data-clipboard-text="orderShow.exp_number" @click="copy" alt="">
          </div>

          <!-- 物流跟踪 -->
          <div class="logistics-box" v-if="logistics.list && logistics.list.length > 0">
            <van-steps direction="vertical" inactive-color="#71777D" active-color="#FF6B29"  :active="logistics_active">
              <van-step v-for="(item,index) in logistics.list" :key="index">
                <h3 class="logistics-tit">{{ item.status }}</h3>
                <p class="logistics-time">{{ item.time }}</p>
              </van-step>
            </van-steps>
          </div>
          <no-data v-else :bottomBg="false"></no-data>

        </div>
        <!--物流详情结束-->


        <!--订单底部按钮开始-->
        <div class="bot-height safety-height" v-if="logistics.list && logistics.list.length > 0"></div>
        <div class="order-show-bot vertical-end safety-height" v-if="logistics.list && logistics.list.length > 0">
            <div class="bot-box vertical-center">
                <div class="bot-btn logistics-btn" @click="orderLink">收起物流信息</div>
            </div>
        </div>
        <!--订单底部按钮结束-->

    </div>
</template>

<script>
import Clipboard from 'clipboard'
import NoData from "components/NoData";
export default {
  name: "record",
  data(){
    return{
      orderShow: {},
      logistics: {},
      logistics_active: -1
    }
  },
  created() {
    if(this.$route.params.id){
      this.getShow(this.$route.params.id);
    }else {
      this.$router.replace({path: '/user/record'})
    }
  },
  methods: {
    // 获取兑换详情
    getShow(id){
      this.$http.get(this.$api.OrdersShow + '/' + id,true).then( res => {
        if(res.data.success){
          this.orderShow = res.data.data;
          if(this.orderShow.status == 1){
            this.logistics_active = 0
          }
          this.getLogistics();
        }
      })
    },
  //  获取物流信息
    getLogistics(){
      let url = this.$api.Logistics + '?number=' + this.orderShow.exp_number + '&type=' + this.orderShow.exp_type;
      this.$http.get(url,true).then( res => {
        if(res.data.success){
          this.logistics = res.data.data;
        }
      })
    },
  //  返回订单详情
    orderLink(){
      // this.$router.replace({path: `/order/record/show/${ this.orderShow.id }`})
      this.$router.go(-1)
    },
    // 点击复制事件
    copy(){
      var clipboard = new Clipboard('.copy-btn');
      clipboard.on('success', e => {
        this.$toast('复制成功');
        // 释放内存
        clipboard.destroy()
      });
      clipboard.on('error', e => {
        // 不支持复制
        this.$toast('该浏览器不支持自动复制');
        // 释放内存
        clipboard.destroy()
      })
    },
  },
    components:{
        NoData
    }
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/user/record/logistics.scss";

</style>

<style>
  .logistics-box .van-step__line{
    background-color: #EEEEEE!important;
  }
  .logistics-box .van-step__circle{
    background-color: #B6B9C1!important;
  }
</style>